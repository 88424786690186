/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

 @import "bootstrap";
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');




ion-content{
    --background: #ffffff;

}
img{
    display: inline-block;
}

ion-header{
    background: #ffffff;
    color: #000;
    padding-top: 10px;
    ion-toolbar{
        --background: none;
        ion-title, ion-menu-button, ion-icon, ion-back-button{
            color: #000;
            font-family: 'Poppins';
        }
        ion-title{
            font-size: 16px;
            color: #5a5a5a;
        }
        ion-back-button{
            min-width: 40px;
            height: 40px;
        }
    }
    .sd_kl{
        width: 40px;
        height: 40px;
        background: #efea44;
        border-radius: 5px;
        margin-left: 10px;
    }
}
.header-md::after{
    background-image: none;
}

@media(min-width: 992px){
    ion-header{
        display: none;
    }
    .mob_content{
        display: none;
    }
}
@media(max-width: 992px){
    .web_header{
        display: none;
    }
    .web_contents{
        display: none;
    }
}
.web_header{
    padding: 20px 10px;
    width: 100%;
    background: #fff;
    font-family: 'Open Sans', sans-serif;
    .web_headtot{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left_cont{
            display: flex;
            align-items: center;
            .logo_cont{
                display: block;
                width: 210px;
                img{
                    display: block;
                    width: 100%;
                }
            }
            .left_menu{
                margin-left: 50px;
                a{
                    cursor: pointer;
                    margin-right: 20px;
                    text-decoration: none;
                    font-weight: 500;
                    color: #505050;
                }
            }
        }
        .right_cont{
            display: flex;
            align-items: center;
            .ser_br{
                ion-searchbar{
                    --box-shadow: none;
                    border: 1px solid #ebebeb;
                    border-radius: 5px;
                    padding: 0;
                    overflow: hidden;
                }
            }
            a{
                cursor: pointer;
                margin-left: 30px;
                font-weight: 500;
                text-decoration: none;
                color: #505050;
            }
        }
    }
}
.web_contents{
    // padding-top: 101px;
    font-family: 'Open Sans', sans-serif;
    .slide_cont{
        ion-card{
            margin: 0;
            border-radius: 5px;
            max-height: 574px;
            overflow: hidden;
        }
    }
}

footer{
    font-family: 'Open Sans', sans-serif;
    .footer_top{
        background: #f5f5f5;
        padding: 35px 0;
        .foot_div{
            h6{
                margin: 15px 0;
            }
            a{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;
                color: #6d6d6d;
                cursor: pointer;
                ion-icon{
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 22px;
                    color: #5a5a5a;
                }
            }
            a:hover{
                color: #000;
            }
        }
    }
    .footer_btm{
        background: #505050;
        padding: 20px 0;
        .ftr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}